/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        $('.hamburger--stand').on('click', function (e) {
            $(this).toggleClass('is-active');
            $('#mobile-menu').toggleClass('hidden show');
            $('body').toggleClass('mobile-closed mobile-open');
            $('.mask').fadeToggle();
            $(window).scrollTop(0);
        });

        if ($('#mobile-menu .menu-item-has-children').length) {
            $('#mobile-menu .menu-item-has-children > a').append('<i class="plus"></i>');
        }

        //Open mobile child menu
        $('#mobile-menu i').on('click', function (e) {
            e.preventDefault();
            if (e.target === $(this)[0]) {
                $(this).toggleClass('open');
                $(this).parent().siblings('.sub-menu').slideToggle(150);
            }
        });

        //Desktop child menu
        $('#desktop-menu .menu > li.menu-item-has-children:not(.pll-parent-menu-item) > a' ).on('click', function(e) {
            e.preventDefault();
            $('.sub-menu').removeClass('el-hover');
            $(this).siblings('.sub-menu').toggleClass('el-hover');
            $('.close-sub-menu').removeClass('hidden');
        });
        $('.sub-menu').on('click', function() {
            $(this).removeClass('el-hover');
            $('.close-sub-menu').addClass('hidden');
        });
        $('.close-sub-menu').on('click', function () {
            $('.sub-menu').removeClass('el-hover');
            $('.close-sub-menu').addClass('hidden');
        })

        //Category select with anchors
        $('select.categories').on('change', function () {
           window.location = $(this).val();
        });

        //Search panel toggle
        $('.search-icon').on('click', function () {
            $('.search-panel').animate({
                right: '0px',
            }, 150);
            $('.mask').fadeIn();
            $('body').toggleClass('overflow');
        });
        $('.close-search').on('click', function () {
            $('.search-panel').animate({
                right: '-400px'
            }, 150);
            $('.mask').fadeOut();
            $('body').toggleClass('overflow');
        });

        //Stage legal button
        if ($('.legal').length) {
            $('.infobox p:last-of-type').append('<i class="show-legal"></i>');
        }
        $('.show-legal').on('click', function () {
            $(this).parent().siblings('.legal').slideToggle(200);
        });

        //Footer menu accordions on mobile
        $('footer .accordion h4').on('click', function () {
            $(this).toggleClass('open');
            $(this).siblings().children('ul.menu').slideToggle(200);
        });


        // Scroll page up
        $('#return-up').on('click', function () {
            $('html, body').animate({
                scrollTop: $('html, body').offset().top,
            });
        });


        //Accordion block
        $('.toggle').click(function (e) {
            $(this).parent().siblings().find('.toggle').removeClass('active');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
            if ($(this).next().hasClass('show')) {
                $(this).next().removeClass('show');
                $(this).next().slideUp(150);
            } else {
                $(this).parent().siblings().find('.answer').removeClass('show');
                $(this).parent().siblings().find('.answer').slideUp(350);
                $(this).next().toggleClass('show');
                $(this).next().slideToggle(150);
            }
        });

        //Tabs block
        $('.tab-headings span.heading').on('click', function () {
            let heading_id = $(this).data('id');
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
            $(this).parent().parent().siblings().children().removeClass('active');
            $(this).parent().parent().siblings().find('.tab-content[data-id=' + heading_id + ']').addClass('active');
        });

        //Audio block
        let player = $('#audio_player');
        $('#play.stop').on('click', function () {
            $(this).toggleClass('stop play');
            player[0].play();
            if ($(this).hasClass('play')) {
                player[0].play();
            } else {
                player[0].pause();
            }
        });

        //Charge counter block
        $('section.charge-counter').each(function () {
            let v1_days = $('#calculated .first .days-value', $(this));
            let v1_capacity = $('#calculated .first .capacity', $(this)).data('v1_capacity');
            let v1_consumption = $('#calculated .first .consumption', $(this)).data('v1_consumption');
            let v1_max_days = $('#calculated .first .capacity', $(this)).data('v1_max_days');

            let v2_days = $('#calculated .second .days-value', $(this));
            let v2_capacity = $('#calculated .second .capacity', $(this)).data('v2_capacity');
            let v2_consumption = $('#calculated .second .consumption', $(this)).data('v2_consumption');
            let v2_max_days = $('#calculated .second .capacity', $(this)).data('v2_max_days');
            let slider_max_range = $('#slider-max-range', $(this)).data('slider_max_range');

            $('#slider-max-range', $(this)).slider({
                range: "min",
                min: 1,
                max: slider_max_range,
                value: 1,
                create: function (event, ui) {
                    $('span.ui-slider-handle', $(this)).html('<div class="value">' + '0 km' + '</div>');
                },
                slide: function (event, ui) {
                    $('span.ui-slider-handle', $(this)).html('<div class="value">' + ui.value + ' km' + '</div>');
                    let v1_consumed_energy = ui.value * v1_consumption;
                    let v1_energy_left = v1_capacity - v1_consumed_energy;
                    let v1_km_left = v1_energy_left / v1_consumption;
                    let v1_days_between_charge = v1_km_left / ui.value;
                    v1_days.text(Math.round(v1_days_between_charge));

                    let v2_consumed_energy = ui.value * v2_consumption;
                    let v2_energy_left = v2_capacity - v2_consumed_energy;
                    let v2_km_left = v2_energy_left / v2_consumption;
                    let v2_days_between_charge = v2_km_left / ui.value;
                    v2_days.text(Math.round(v2_days_between_charge));
                }
            });
        });

        //Detect if dealer/service table overflows (and requires horizontal scroll bar)
        if ($('.table-wrapper table').outerWidth() > $('.table-wrapper').width() ) {
            $('.table-wrapper .gradient-overlay').addClass('visible');
        }

        //Pre select dealer/service dropdown based on query string
        if ($('.populate-dealers select').length) {
            let dealer_id = (new URL(location.href)).searchParams.get('dealer_id');
            $('.populate-dealers select').val(dealer_id);
        }
        if ($('.populate-services select').length) {
            let service_id = (new URL(location.href)).searchParams.get('service_id');
            $('.populate-services select').val(service_id);
        }

        const videos = document.querySelectorAll('.autoplayVideo');
        function playVideo(entry, observer) {
            entry.forEach(function (entryItem) {
                if (entryItem.isIntersecting) {
                    // Play the video associated with the current entry
                    const video = entryItem.target;
                    video.play();
                    observer.unobserve(video); // Stop observing this video once it starts playing
                }
            });
        }

        const observer = new IntersectionObserver(playVideo, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        });

        videos.forEach(function (video) {
            observer.observe(video);
        });
    });

    //Header behaviour
    let header = $('header.primary');
    let sticky_menu = $('section.sticky-menu');
    let lastScrollTop = 0;
    let delta = 30;
    $(window).scroll(function () {
        var nowScrollTop = $(this).scrollTop();
        if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
            if (nowScrollTop > lastScrollTop) {
                header.addClass('hide');
                header.removeClass('show');
                sticky_menu.css({'bottom': '0', 'top': '0'})

            } else {
                header.addClass('show');
                header.removeClass('hide');
                sticky_menu.css('top', '84px')

            }
            lastScrollTop = nowScrollTop;
        }
        let scroll = $(window).scrollTop();
        if (scroll >= 5) {
            header.addClass('blue');
            header.removeClass('transparent');
        } else {
            header.addClass('transparent');
            header.removeClass('blue');
        }

        let return_up = $('#return-up');
        if (nowScrollTop > 200) {
            return_up.fadeIn();
        } else {
            return_up.fadeOut();
        }
    });
    // If sticky menu is immediately after Stage block set sticky margin-top -60px.
    if (sticky_menu.prev().hasClass('stage')) {
        sticky_menu.css('margin-top', '-60px')
    }

    //Image and text block hover styles
    let anchor = $('section.image-and-text a');
    anchor.hover(function () {
        $(this).parent().siblings().toggleClass('scale-on scale-off');
    });

    //Add subheader classes for dealer select
    if ($('.populate-services select').length) {
        $('.populate-services option[value="subheading"]').addClass('subheading').attr('disabled', true);
    }
    if ($('.populate-dealers select').length) {
        $('.populate-dealers option[value="subheading"]').addClass('subheading').attr('disabled', true);
    }

})(jQuery);
